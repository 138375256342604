import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '../tools/withStyles';
import { Main } from '../components/Main';
import { Button } from '../components/Button';
import { Text } from '../components/Text';
import { Fader } from '../components/Fader';
import { Secuence } from '../components/Secuence';

const styles = theme => ({
  root: {}
});

class Bag extends React.Component {
  static propTypes = {
    classes: PropTypes.object
  };

  render () {
    const { classes } = this.props;

    return (
      <Main className={classes.root}>
        <Secuence stagger>
          <article>
            <Fader>
            <header>
                <h1><Text>Quick Start</Text></h1>
              </header>
              <blockquote>
                $BAG numbers 1-8999 is free to claim and dependent on Ethereum's gas price to mint.
              </blockquote>
              <div style={{ margin: [0, 'auto'], height: 'auto', padding: 15, width: '656x', textAlign: 'center' }}>
                <a href='https://docs.cryptoodles.com/toodl-nfts/redeeming-loot-bag-nfts' >
                  <Button
                  >Mint</Button>
                </a>
                <div style={{ width: '25px', height: 'auto', display: 'inline-block' }}> </div>
                <a href='https://opensea.io/collection/toodle-loot-bag' >
                  <Button
                  >Trade</Button>
                </a>
              </div>
              <header>
                <h1><Text>The Toodle $BAG</Text></h1>
              </header>
              <p>
                <Text>
                  When minted, this is what your Toodle $BAG looks like for the first time:
                </Text>
              </p>
              <img
                src='https://openseauserdata.com/files/9ad140d19255d088ae4191f99b13738a.svg'
                alt='Toodle Loot Bag #1'
              />
              <p><Fader>A Toodle $BAG is a limited edition, authentication token designed to give the token holder access to the Cryptoodles community. Examples:</Fader></p>
              <Fader style={{ display: 'block' }}>
                <ul>
                  <li>Opportunity to mint the first Toodles (Genesis Toodles) to clone more Toodles for others</li>
                  <li>Owning digital land in the Cryptoodles Metaverse</li>
                  <li>Ability to cast votes to help guide the Cryptoodles project direction</li>
                </ul>
              </Fader>
              <Fader>
                <p>
                  If you are lucky enough to mint a Toodle $BAG before all have been claimed, congratulations! You are holding a part of Cryptoodle's ancient history. That same $BAG you minted is actually: <br />
                </p>
                <img
                  src='https://857234157-files.gitbook.io/~/files/v0/b/gitbook-x-prod.appspot.com/o/spaces%2F-MbZMNv8BaxZA2zLIamL%2Fuploads%2FWiNdN5qBxCqMoUuZpAYc%2Ftoodle_loot_bag_1.png?alt=media&token=e596d082-ba9e-4ef0-845f-af1957480de3'
                  alt='Toodle Loot Bag #1 in the Cryptoodle Metaverse'
                />
              </Fader>
              <header>
                <h1>Genesis Toodles</h1>
              </header>
              <p>
                Each $BAG is associated with a Genesis Toodle that can be used to genetically clone more Toodles for others to join the community with. The Genesis Toodle is where the ancestry line of a Toodle begins. Imagine owning a Genesis Toodle, and the genetic makeup of 1000 Toodles that others use started from your Genesis Toodle! <br />
              </p>
              <header>
                <h1>Relics</h1>
              </header>
              <p>
                Each $BAG has a unique list of items which all are a part of the ”Relics of Toodles”. Relics can range from space ships to heavy weapons, to accessories and legendary skills. Owning a Relic will unlock unique visuals for the holder’s Toodle that cannot be obtained in any other way asides from being one of the lucky few to mint a $BAG. <br />
                <br />As the Cryptoodle Metaverse expands and new items are crafted by Toodles, these Relics will become more valuable since Relics cannot be crafted.
              </p>
              <img
                src='https://857234157-files.gitbook.io/~/files/v0/b/gitbook-x-prod.appspot.com/o/spaces%2F-MbZMNv8BaxZA2zLIamL%2Fuploads%2Fwr4l8DSqh4XgZi0AU3fw%2Fimage.png?alt=media&token=a0dd5ee2-1545-4c30-8323-a82a8bcc7a37'
                alt='Relics in the Cryptoodle Metaverse'
              />
              <header>
                <h1>Digital Spaces</h1>
              </header>
              <p>
                Owning a "Digital Space" is equivalent to owning land in the Cryptoodle Metaverse. Owning a Digital Space allows someone to:
                <ul>
                  <li>Build houses and business buildings for Toodles. A group of spaces with buildings may develop into a town.</li>
                  <li>Harvest the raw minerals within the space needed to craft new items and buildings.</li>
                  <li>Help expand the Cryptoodle Metaverse for Toodles to explore different areas.</li>
                </ul>
                <img
                  src='https://857234157-files.gitbook.io/~/files/v0/b/gitbook-x-prod.appspot.com/o/spaces%2F-MbZMNv8BaxZA2zLIamL%2Fuploads%2Fd3Dv4982HtYidDwWofyP%2Fimage.png?alt=media&token=7dc0e7f7-fe9d-44c0-9b84-0ed12ebc077d'
                  alt='A Digital Space in the Cryptoodle Metaverse'
                />
              </p>
              <header>
                <h1>Summary</h1>
              </header>
              <p>
                The Toodle $BAGs you mint provide building blocks for the Cryptoodle project, a unique approach you won't find in most blockchain projects. These building blocks are:
                <ul>
                  <li>Toodles - A Companion</li>
                  <li>$BAG - Authentication Token</li>
                  <li>Relics - Ancient Collectibles</li>
                  <li>Digital Spaces - Online Land</li>
                  <li>$TODL Token - Digital Credits</li>
                </ul>
                Together these building blocks allow Toodles to build the Cryptoodle Metaverse as it expands.
              </p>
            </Fader>
          </article>
        </Secuence>
      </Main>
    );
  }
}

export default withStyles(styles)(Bag);
